import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import CircleLoader from 'react-spinners/CircleLoader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltipLite from 'react-tooltip-lite';
import Autosuggest from 'react-autosuggest';
import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';
import Papa from 'papaparse';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import CTAComponent from '../components/cta';
import Pagination from '../components/pagination';
import MagnifierIcon from './../assets/images/icon-magnifier-2.svg';
import BackImg from './../assets/images/wave21.png';
import BackMobileImg from './../assets/images/wave21-mobile.png';

const Heading = styled.div`
    padding-top: 130px;
    display: flex;
    justify-content: center;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    img {
        object-fit: contain;
    }

    @media screen and (max-width: 767px) {
        width: 244px;
    }
`;
const Desc = styled.div`
    margin-top: 26px;
    font-size: 23px;
    line-height: 32px;
    color: white;
    font-family: 'Neuzeit Grotesk';
    text-align: center;
    @media screen and (max-width: 767px) {
        margin-top: 31px;
    }
`;
const Background = styled.div`
    background: url(${BackImg}) no-repeat;
    background-size: 100%;
    background-position: top 275px left 0px;
    @media screen and (max-width: 767px) {
        background: url(${BackMobileImg}) no-repeat top;
        background-position: top 335px left 0px;
    }
`;
const BrandsSection = styled.div`
    margin-top: 65px;
    z-index: 9;
    width: fit-content;
    margin-bottom: 40px;
    width: 100%;
    @media screen and (max-width: 767px) {
        margin-top: 33px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        margin-bottom: 128px;
    }
`;
const BrandsTitle = styled.div`
    font-family: 'Neuzeit Grotesk';
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    margin-left: 232px;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
    }
`;
const BrandsContainer = styled.div`
    width: 100%;
    margin-top: 13px;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
const TableContainer = styled.div`
    margin-top: 38px;
    margin-left: 64px;
    margin-right: 64px;
    background: white;
    border-radius: 8px;
    margin-bottom: 32px;
    @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
        border-radius: 0;
        margin-bottom: 32px;
    }
`;
const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    padding: 30px 24px;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        padding: 24px 24px 16px 24px;
    }
`;
const SearchContainer = styled.div`
    display: flex;
    width: 60%;
    height: 48px;
    border: 1px solid #adadad;
    border-radius: 24px;
    background: #fafafa;
    padding-right: 24px;
    padding-left: 14px;
    align-items: center;
    @media screen and (max-width: 767px) {
        width: calc(100% - 24px);
    }
`;
const SearchInput = styled.input`
    color: rgba(25, 23, 26, 0.9);
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    border: none;
    outline: none;
    background: #fafafa;
`;
const AutoSuggestItem = styled.div`
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
`;
const QuickSearchContainer = styled.div`
    width: 35%;
    @media screen and (max-width: 767px) {
        margin-top: 8px;
        width: 100%;
    }
`;
const QuickSearchLabel = styled.label`
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    margin-right: 10px;
    margin-left: 30px;
    width: 50%;
`;
const QuickSearchSelect = styled.select`
    font-size: 15px;
    width: 50%;
`;
const HeaderDesc = styled.div`
    text-align: center;
    padding-right: 24px;
    padding-left: 14px;
    padding-top: 24px;
`;
const HeaderDescTitle = styled.div`
    color: #19171a;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-family: 'Renogare-Regular';
`;
const HeaderDescParagraph = styled.div`
    font-size: 15px;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(25, 23, 26, 0.9);
    margin-top: 12px;
    font-family: 'Neuzeit Grotesk';
    a {
        color: #b68f54;
        text-decoration: none;
    }
    @media screen and (max-width: 767px) {
        margin-top: 8px;
    }
`;
const CopyID = styled.span`
    color: #b68f54;
    cursor: pointer;
    margin-left: 12px;
    white-space: nowrap;
    &:hover {
        color: #806040;
    }
    &:focus {
        color: #593f2d;
    }
`;
const TableTopRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #19171a;
    font-family: 'Renogare-Regular';
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 20px;
    background: #e0e0e0;
    padding: 10px 0;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const TableRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    color: rgba(25, 23, 26, 0.9);
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    &:last-child {
        border-bottom: none;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    @media screen and (max-width: 767px) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 14px 0;
    }
`;
const TableCell = styled.div`
    padding: 0 16px;
    display: flex;
    width: ${props => `calc(${props.width}% - 32px)`};
    justify-content: ${props => props.align || 'flex-start'};
    span {
        font-family: 'Neuzeit Grotesk';
    }
`;

const DesktopLayout = styled.div`
    display: block;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

const MobileLayout = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
`;
const MobileRow = styled.div`
    display: flex;
    width: 100%;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 6px;
    margin-bottom: 6px;
`;
const MobileRowName = styled.div`
    color: #19171a;
    font-family: 'Renogare-Regular';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-align: left;
    width: 140px;
`;
const MobileRowValue = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 15px;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(25, 23, 26, 0.7);
    text-align: left;
    width: 100%;
`;
const Main = styled.div`
    margin-bottom: 184px;
    @media screen and (max-width: 767px) {
        margin-bottom: 136px;
    }
`;
const Logo = styled.div`
    height: 96px;
    width: 150px;
    margin: 8px;
`;
const LoadingContainer = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;
    display: ${props => (props.visible ? 'flex' : 'none')};
    justify-content: center;
`;
const NoResults = styled.div`
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;
    font-family: 'Renogare-Regular';
`;
const csvUrl =
    'https://audigent-dashboard-assets.s3-us-west-2.amazonaws.com/corporate-site/pmp_list.csv';
const itemsPerPage = 50;
const quickSearchList = [
    {
        label: 'Behavior',
        keywords: 'behavior',
    },
    {
        label: 'Blockchain & Crypto',
        keywords: 'blockchain',
    },
    {
        label: 'Brand',
        keywords: 'brand',
    },
    {
        label: 'Demographic',
        keywords: 'demographic',
    },
    {
        label: 'DIY',
        keywords: 'diy',
    },
    {
        label: 'Entertainment',
        keywords: 'entertainment',
    },
    {
        label: 'Food',
        keywords: 'food',
    },
    {
        label: 'Health and Wellness',
        keywords: 'health',
    },
    {
        label: 'Industry',
        keywords: 'industry',
    },
    {
        label: 'Interest and Affinity',
        keywords: 'interest',
    },
    {
        label: 'Lifestyle',
        keywords: 'lifestyle',
    },
    {
        label: 'Mobile Device',
        keywords: 'mobile',
    },
    {
        label: 'Moms',
        keywords: 'moms',
    },
    {
        label: 'Music',
        keywords: 'music',
    },
    {
        label: 'Nightlife Enthusiasts',
        keywords: 'nightlife',
    },
    {
        label: 'Parenting and Children',
        keywords: 'parenting',
    },
    {
        label: 'Social Media and Streaming',
        keywords: 'social media',
    },
    {
        label: 'Sports',
        keywords: 'sports',
    },
    {
        label: 'Technology',
        keywords: 'technology',
    },
    {
        label: 'Television Award Show Audiences',
        keywords: 'television award',
    },
    {
        label: 'Travel',
        keywords: 'travel',
    },
];
export default function AdSmartPMPPage({ data }) {
    let [tableData, setTableData] = useState([]);
    let [filterData, setFilterData] = useState([]);
    const [selectedToolTip, setSelectedTooltip] = useState(null);
    const [loading, setLoading] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageIsVisible, setPageIsVisible] = useState(true);
    const [searchWord, setSearchWord] = useState('');
    const [suggestionOptions, setSuggestionOptions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const handleVisibilityChange = isVisible => {
        setPageIsVisible(isVisible);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const handlePage = page => {
        setCurrentPage(page);
    };
    useEffect(() => {
        let array = tableData.filter(item => {
            return item['Keywords']
                .toLowerCase()
                .includes(searchWord.toLowerCase());
        });
        setCurrentPage(0);
        setFilterData(array);

        return () => {};
    }, [searchWord]); // eslint-disable-line

    useEffect(() => {
        async function getData() {
            try {
                const response = await fetch(csvUrl);
                const csv = await response.text();
                const results = Papa.parse(csv, {
                    header: true,
                    skipEmptyLines: true,
                });
                const rows = results.data;
                setFilterData(rows);
                setTableData(rows);
                setSuggestionOptions(
                    rows.reduce((acc, curr) => {
                        const keywords = curr['Keywords'].split(', ');
                        return [...new Set([...acc, ...keywords])];
                    }, [])
                );
                setLoading(0);
            } catch (err) {
                console.error(err);
            }
        }
        getData();
    }, []); // eslint-disable-line

   const onSearch = e => {
        setSearchWord(e.target.value);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        const filteredValues =
            inputLength === 0
                ? []
                : suggestionOptions.filter(
                      suggestion =>
                          suggestion.toLowerCase().indexOf(inputValue) > -1
                  );
        setSuggestions(filteredValues);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const { hero, data_partners, book_now, seo_content } = data.butterPage;
    const headerDescParagraph = `Don’t see your DSP? No problem, contact us at <a href='mailto:info@audigent.com'>info@audigent.com</a> and we’ll make sure we get you the deal you’re looking for.`;

    return (
        <Layout menu="FOR ADVERTISERS">
            <SEOComponent
                title="SmartPMP Search"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Background>
                <Heading>
                    <img src={hero[0].image} alt="" width="100%" />
                </Heading>

                <Desc>{hero[0].description}</Desc>
                {data_partners[0] &&
                    data_partners[0].logos &&
                    data_partners[0].logos.length > 0 && (
                        <BrandsSection>
                            <BrandsTitle>
                                {data_partners[0].description}
                            </BrandsTitle>
                            <BrandsContainer>
                                <PageVisibility
                                    onChange={handleVisibilityChange}
                                >
                                    {pageIsVisible && (
                                        <Ticker speed={7} direction="toRight">
                                            {({ index }) => (
                                                <Logo>
                                                    <img
                                                        src={
                                                            data_partners[0]
                                                                .logos[
                                                                index %
                                                                    data_partners[0]
                                                                        .logos
                                                                        .length
                                                            ].image
                                                        }
                                                        alt={
                                                            data_partners[0]
                                                                .logos[
                                                                index %
                                                                    data_partners[0]
                                                                        .logos
                                                                        .length
                                                            ].title
                                                        }
                                                        style={{
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </Logo>
                                            )}
                                        </Ticker>
                                    )}
                                </PageVisibility>
                            </BrandsContainer>
                        </BrandsSection>
                    )}
                <Main>
                    <TableContainer>
                        <HeaderDesc>
                            <HeaderDescTitle>
                                Now Available on Xandr, TTD, Amobee, Verizon,
                                and DV360.
                            </HeaderDescTitle>
                            <HeaderDescParagraph dangerouslySetInnerHTML={{__html: headerDescParagraph}} />
                        </HeaderDesc>
                        <TableHeader>
                            <SearchContainer>
                                <div
                                    style={{
                                        width: 20,
                                        marginRight: 10,
                                    }}
                                >
                                    <img
                                        src={MagnifierIcon}
                                        alt=""
                                        width="100%"
                                    />
                                </div>
                                <Autosuggest
                                    suggestions={suggestions.slice(0, 10)}
                                    onSuggestionsFetchRequested={
                                        onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                        onSuggestionsClearRequested
                                    }
                                    getSuggestionValue={suggestion =>
                                        suggestion
                                    }
                                    onSuggestionSelected={(
                                        ev,
                                        { suggestionValue }
                                    ) => {
                                        ev.preventDefault();
                                        setSearchWord(suggestionValue);
                                    }}
                                    renderSuggestion={suggestion => (
                                        <AutoSuggestItem>
                                            {suggestion}
                                        </AutoSuggestItem>
                                    )}
                                    inputProps={{
                                        value: searchWord,
                                        placeholder: 'Search by keyword',
                                        onChange: onSearch,
                                        disabled: loading,
                                    }}
                                    renderInputComponent={inputProps => (
                                        <SearchInput {...inputProps} />
                                    )}
                                    theme={{
                                        container: {
                                            width: '100%',
                                            position: 'relative',
                                        },
                                        suggestionsContainer: {
                                            display: 'none',
                                        },
                                        suggestionsContainerOpen: {
                                            display: 'block',
                                            background: '#fff',
                                            position: 'absolute',
                                            width: '100%',
                                            border: '1px solid #666',
                                        },
                                        suggestionsList: {
                                            listStyle: 'none',
                                            margin: 0,
                                            padding: 0,
                                        },
                                        suggestion: {
                                            padding: '6px 10px',
                                        },
                                        suggestionHighlighted: {
                                            background: '#ccc',
                                        },
                                    }}
                                />
                            </SearchContainer>
                            <QuickSearchContainer>
                                <QuickSearchLabel htmlFor="quick-search">
                                    Quick Search:
                                </QuickSearchLabel>
                                <QuickSearchSelect
                                    id="quick-search"
                                    onChange={e => {
                                        let keyword = e.target.value;
                                        if (keyword === 'all') {
                                            keyword = '';
                                        }
                                        setSearchWord(keyword);
                                    }}
                                >
                                    <option value="all">All</option>
                                    {quickSearchList.map(el => (
                                        <option
                                            value={el.keywords}
                                            key={el.label}
                                        >
                                            {el.label}
                                        </option>
                                    ))}
                                </QuickSearchSelect>
                            </QuickSearchContainer>
                        </TableHeader>
                        <TableTopRow>
                            <TableCell width={25}>Deal Name</TableCell>
                            <TableCell width={17}>Deal ID</TableCell>
                            <TableCell width={8}>Format</TableCell>
                            <TableCell width={13}>Avg Floor (dCPM)</TableCell>
                            <TableCell width={37}>Keywords</TableCell>
                        </TableTopRow>
                        <LoadingContainer visible={loading}>
                            <CircleLoader
                                size={150}
                                color="#b68f54"
                                loading={true}
                            />
                        </LoadingContainer>
                        {!loading && filterData.length === 0 && (
                            <NoResults>No results found</NoResults>
                        )}
                        {filterData.map((row, i) => {
                            return currentPage * itemsPerPage <= i &&
                                i < filterData.length &&
                                i < (currentPage + 1) * itemsPerPage ? (
                                <React.Fragment key={`key${i}`}>
                                    <DesktopLayout>
                                        <TableRow>
                                            <TableCell width={25}>
                                                {row['Deal Name']}
                                            </TableCell>
                                            <TableCell width={17}>
                                                <div style={{ width: '100%' }}>
                                                    {row['Deal ID']}
                                                </div>
                                                <div style={{ width: 100 }}>
                                                    <ReactTooltipLite
                                                        content="Copied to Clipboard"
                                                        className="tooltip-class"
                                                        isOpen={
                                                            selectedToolTip ===
                                                            `desktop-${i}`
                                                        }
                                                    >
                                                        <CopyToClipboard
                                                            text={
                                                                row['Deal ID']
                                                            }
                                                            onCopy={row => {
                                                                setSelectedTooltip(
                                                                    `desktop-${i}`
                                                                );
                                                                setTimeout(
                                                                    () => {
                                                                        setSelectedTooltip(
                                                                            null
                                                                        );
                                                                    },
                                                                    2000
                                                                );
                                                            }}
                                                        >
                                                            <CopyID>
                                                                Copy ID
                                                            </CopyID>
                                                        </CopyToClipboard>
                                                    </ReactTooltipLite>
                                                </div>
                                            </TableCell>
                                            <TableCell width={8}>
                                                {row['Format']}
                                            </TableCell>
                                            <TableCell
                                                width={13}
                                                align="center"
                                            >{`$${row['Price dCPM']}`}</TableCell>
                                            <TableCell width={37}>
                                                {row['Keywords']}
                                            </TableCell>
                                        </TableRow>
                                    </DesktopLayout>
                                    <MobileLayout>
                                        <TableRow>
                                            <MobileRow>
                                                <MobileRowName>
                                                    Deal Name
                                                </MobileRowName>
                                                <MobileRowValue>
                                                    {row['Deal Name']}
                                                </MobileRowValue>
                                            </MobileRow>
                                            <MobileRow>
                                                <MobileRowName>
                                                    Deal ID
                                                </MobileRowName>
                                                <MobileRowValue>
                                                    {row['Deal ID']}
                                                    <ReactTooltipLite
                                                        content="Copied to Clipboard"
                                                        className="tooltip-class"
                                                        isOpen={
                                                            selectedToolTip ===
                                                            `mobile-${i}`
                                                        }
                                                    >
                                                        <CopyToClipboard
                                                            text={
                                                                row['Deal ID']
                                                            }
                                                            onCopy={row => {
                                                                setSelectedTooltip(
                                                                    `mobile-${i}`
                                                                );
                                                                setTimeout(
                                                                    () => {
                                                                        setSelectedTooltip(
                                                                            null
                                                                        );
                                                                    },
                                                                    2000
                                                                );
                                                            }}
                                                        >
                                                            <CopyID>
                                                                Copy ID
                                                            </CopyID>
                                                        </CopyToClipboard>
                                                    </ReactTooltipLite>
                                                </MobileRowValue>
                                            </MobileRow>
                                            <MobileRow>
                                                <MobileRowName>
                                                    Format
                                                </MobileRowName>
                                                <MobileRowValue>
                                                    {row['Format']}
                                                </MobileRowValue>
                                            </MobileRow>
                                            <MobileRow>
                                                <MobileRowName>
                                                    Avg Floor (dCPM)
                                                </MobileRowName>
                                                <MobileRowValue>{`$${row['Price dCPM']}`}</MobileRowValue>
                                            </MobileRow>
                                            <MobileRow>
                                                <MobileRowName>
                                                    Keywords
                                                </MobileRowName>
                                                <MobileRowValue>
                                                    {row['Keywords']}
                                                </MobileRowValue>
                                            </MobileRow>
                                        </TableRow>
                                    </MobileLayout>
                                </React.Fragment>
                            ) : null;
                        })}
                    </TableContainer>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={
                            Math.floor(filterData.length / itemsPerPage) + 1
                        }
                        onNext={() => handleNextPage()}
                        onPrev={() => handlePrevPage()}
                        onPage={page => handlePage(page)}
                    />
                </Main>
                <CTAComponent {...book_now[0]} />
            </Background>
        </Layout>
    );
}

export const adSmartPMPSearchPageQuery = graphql`
    query {
        butterPage(slug: { eq: "smart-pmp-search" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                description
                image
            }

            data_partners {
                description
                logos {
                    title
                    image
                }
            }

            book_now {
                button_title
                title
            }
        }
    }
`;
